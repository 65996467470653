
export default {
  Canvas: {
    CanvasWidth: 500,
    CanvasHeight: 200,
    CanvasBorderWeight: .5,
    CanvasBorderColor: '#E0E0DF',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 500,
    CanvasChartHeight: 200,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
    {AREA: 'Overall', PE_WK: 4.1, PL_WK: 4.1, AC_WK: 4.1, FC_WK: 3.3, DE_WK: 1.1, DL_WK: 0.8, PE_CUM: 50.1, PL_CUM: 40.1, AC_CUM: 43.3, FC_CUM: 66.6, DE_CUM: 11.1, DL_CUM: 9.9},
    {AREA: 'Hull',    PE_WK: 5.3, PL_WK: 4.3, AC_WK: 4.7, FC_WK: 3.3, DE_WK: 1.1, DL_WK: 0.8, PE_CUM: 48.3, PL_CUM: 38.3, AC_CUM: 40.5, FC_CUM: 66.6, DE_CUM: 11.1, DL_CUM: 9.9},
    {AREA: 'Topside', PE_WK: 5.2, PL_WK: 5.2, AC_WK: 5.2, FC_WK: 3.3, DE_WK: 1.1, DL_WK: 0.8, PE_CUM: 35.2, PL_CUM: 35.2, AC_CUM: 50,   FC_CUM: 66.6, DE_CUM: 11.1, DL_CUM: 9.9},
    {AREA: 'LQ',      PE_WK: 1.2, PL_WK: 1.2, AC_WK: 1.2, FC_WK: 3.3, DE_WK: 1.1, DL_WK: 0.8, PE_CUM: 23.2, PL_CUM: 24.2, AC_CUM: 27,   FC_CUM: 66.6, DE_CUM: 11.1, DL_CUM: 9.9},
  ],

  Queries: {

    SQL1: [
      {
        VIEW_TITLE:         'N',
        VIEW_OVERALL:       'Y',
        VIEW_DETAIL:        'Y',

        WIDTH:              490,

        TITLE_TEXT:         'Detail Engineering',
        TITLE_TSIZE:        14,
        TITLE_TCOLOR:       '#000000',
        TITLE_ANCHOR:       'start',

        OA_CURRENT_TSIZE:   11,
        OA_CURRENT_TCOLOR:  '#4CAE4E',
        OA_TSIZE:           12,
        OA_TCOLOR:          '#333333',
        OA_BAR_BGCOLOR:     '#F7F3D2',
        OA_BAR_BGOPACITY:   0.61,
        OA_BAR_COLOR:       'Yellow',
        OA_BAR_OPACITY:     1,
        OA_BAR_TSIZE:       12,
        OA_BAR_TCOLOR:      '#000000',
        OA_BAR_HEIGHT:      20.5,
        OA_WEEK_VISIBLE:    '',

        DT_ROW_HEIGHT:      20,
        DT_G_WIDTH:         70,
        DT_G_NAME:          'Area',
        DT_G_COLUMN:        'AREA',
        DT_BAR_WIDTH:       100,
        DT_BAR_BGCOLOR:     '#F4F4F5' ,
        DT_BAR_BGOPACITY:   1,
        DT_BAR_COLOR:       '#FEED57',
        DT_BAR_OPACITY:     1,
        DT_BAR_TSIZE:       11,
        DT_BAR_TCOLOR:      '#757575'
      }
    ],

  },

}